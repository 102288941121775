export default {
  type: "equipments",
  name: null,
  excerpt: "",
  icon: null,
  relationshipNames: [
    "category",
  ],
  category: {
    type: "equipment-categories",
    id: null,
  },
  createdBY: {
    type: "users",
    id: null,
  },
};
