<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <div class="row">
          <dl>
            <dt>{{ $t("EQUIPMENTS.EQUIPMENT_NAME") }}</dt>
            <dd>
              {{ equipment.name }}
            </dd>
          </dl>
          <dl
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_EQUIPMENT_CATEGORIES)
            "
          >
            <dt>{{ $t("COMMON.CATEGORY") }}</dt>
            <dd>
              <equipment-category :equipmentCategory="equipment.category" />
            </dd>
          </dl>
          <dl v-else>
            <dt>{{ $t("COMMON.CATEGORY") }}</dt>
            <dd>
              {{ equipment.category?.name }}
            </dd>
          </dl>
        </div>
        <div class="row full" v-if="equipment.excerpt">
          <dl>
            <dt>{{ $t("COMMON.EXCERPT") }}</dt>
            <dd>
              <div v-html="equipment.excerpt"></div>
            </dd>
          </dl>
        </div>
        <div class="row">
          <dl>
            <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
            <dd>
              {{ equipment.created_at | moment("DD/MM/YYYY") }}
            </dd>
          </dl>

          <dl>
            <dt>{{ $t("COMMON.CREATED_BY") }}</dt>
            <dd>
              {{ equipment.createdBy?.firstname }}
              {{ equipment.createdBy?.lastname }}
            </dd>
          </dl>
        </div>
        <div class="row">
          <dl>
            <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
            <dd>
              {{ equipment.updated_at | moment("DD/MM/YYYY") }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EquipmentCategory from "@/components/EquipmentCategory";
export default {
  name: "equipment-view-global",

  props: ["equipment"],

  components: { EquipmentCategory },

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    equipmentUpdated() {
      this.$emit("equipmentUpdated", true);
    },
  },

  mounted() {},

  watch: {
    equipment(equipment) {},
  },
};
</script>
