<template>
  <div class="container-fluid">
    <div class="configuration-wrapper">
      <h1>{{ $t("APPS.CONFIGURATION_MODULE") }}</h1>
      <div class="kw-breadcrumb">
        <ul>
          <li>
            <img src="../../../../../public/img/kw-home.svg" alt="icon" />
            <span>{{ $t("APPS.CONFIGURATION_MODULE") }}</span>
          </li>
          <li v-if="$route.name === 'List SpotCategories'">
            {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
          </li>
          <li v-if="$route.name === 'List SpotTypes'">
            {{ $t("CONFIGURATION.SPOT_TYPES") }}
          </li>
          <li v-if="$route.name === 'List Equipments'">
            {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
          </li>
          <li v-if="$route.name === 'List EquipmentCategories'">
            {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
          </li>
        </ul>
      </div>
      <ul class="sub-menu-items">
        <li
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_CATEGORIES)"
        >
          <router-link
            :to="{ name: 'List SpotCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SPOT_TYPES)">
          <router-link
            :to="{ name: 'List SpotTypes' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.SPOT_TYPES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENT_CATEGORIES)
          "
        >
          <router-link
            :to="{ name: 'List EquipmentCategories' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENT_CATEGORIES") }}
              </span>
            </a>
          </router-link>
        </li>

        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_EQUIPMENTS)">
          <router-link
            :to="{ name: 'List Equipments' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <h3>{{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}</h3>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openEquipmentCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_EQUIPMENTS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("EQUIPMENTS.ADD_EQUIPMENT")
            }}</span>
          </base-button>
        </div>
      </div>

      <equipment-list-table
        @onViewEquipment="openEquipmentViewModal"
        @onEditEquipment="openEquipmentEditModal"
        @onDeleteEquipment="deleteEquipment"
        @onAddEquipment="openEquipmentCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewEquipmentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewEquipmentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'EQUIPMENT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeEquipmentModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>{{ $t("EQUIPMENTS.VIEW_EQUIPMENT") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-equipment-component
              v-if="openEquipment"
              :equipmentId="openEquipment.id"
              @onEditEquipment="openEquipmentEditModal"
              @onDeleteEquipment="deleteEquipment"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditEquipmentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditEquipmentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'EQUIPMENT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeEquipmentModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("EQUIPMENTS.EDIT_EQUIPMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-equipment-component
              v-if="openEquipment"
              :equipmentId="openEquipment.id"
              @onViewEquipment="openEquipmentViewModal(openEquipment)"
              @onCloseEquipmentModal="closeEquipmentModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddEquipmentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddEquipmentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'EQUIPMENT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeEquipmentModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("EQUIPMENTS.ADD_EQUIPMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-equipment-component
              @onViewEquipment="openEquipmentViewModal"
              @onCloseEquipmentModal="closeEquipmentModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import EquipmentListTable from "./partials/EquipmentListTable.vue";
import EditEquipmentComponent from "./components/EditEquipmentComponent.vue";
import AddEquipmentComponent from "./components/AddEquipmentComponent.vue";
import ViewEquipmentComponent from "./components/ViewEquipmentComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    EquipmentListTable,
    // NotificationSubscription,
    EditEquipmentComponent,
    AddEquipmentComponent,
    ViewEquipmentComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const equipmentId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewEquipmentModalOpened = false;
    let isEditEquipmentModalOpened = false;
    let isAddEquipmentModalOpened = false;
    let openEquipment = null;
    if (equipmentId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewEquipmentModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditEquipmentModalOpened = true;
      }
      openEquipment = { id: equipmentId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddEquipmentModalOpened = true;
    }
    return {
      isViewEquipmentModalOpened: isViewEquipmentModalOpened,
      isEditEquipmentModalOpened: isEditEquipmentModalOpened,
      isAddEquipmentModalOpened: isAddEquipmentModalOpened,
      openEquipment: openEquipment,
      renderKey: 1,
    };
  },

  methods: {
    openEquipmentCreateModal() {
      this.closeEquipmentModal();
      this.isAddEquipmentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Equipments",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openEquipmentViewModal(equipment, reRender = false) {
      this.closeEquipmentModal();
      this.openEquipment = equipment;
      this.isViewEquipmentModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Equipments",
          query: { id: this.openEquipment.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openEquipmentEditModal(equipment) {
      this.closeEquipmentModal();
      this.openEquipment = equipment;
      this.isEditEquipmentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Equipments",
          query: { id: this.openEquipment.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },

    closeEquipmentModal() {
      this.isAddEquipmentModalOpened = false;
      this.isViewEquipmentModalOpened = false;
      this.isEditEquipmentModalOpened = false;
      this.openEquipment = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Equipments",
          query: {},
        }).href
      );
    },

    async deleteEquipment(equipment) {
      const confirmation = await swal.fire({
        text: this.$t("EQUIPMENTS.DELETE_THIS_EQUIPMENT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("equipments/destroy", equipment.id);
          this.renderKey++;
          this.closeEquipmentModal();
          this.$notify({
            type: "success",
            message: this.$t("EQUIPMENTS.EQUIPMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
