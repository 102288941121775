<template>
  <div class="container-fluid">
    <equipment-form
      :loading="loading"
      :equipmentData="equipment"
      :formErrors="formErrors"
      @equipmentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseEquipmentModal="onCloseEquipmentModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultEquipment from "../defaultEquipment";
import EquipmentForm from "../partials/EquipmentForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    EquipmentForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      equipment: cloneDeep(defaultEquipment),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(equipment) {
      this.loading = true;

      const equipmentData = cloneDeep(equipment);
      delete equipmentData.id;

      try {
        await this.$store.dispatch("equipments/add", equipmentData);
        this.$notify({
          type: "success",
          message: this.$t("EQUIPMENTS.EQUIPMENT_ADDED"),
        });
        const equipment = await this.$store.getters["equipments/equipment"];
        this.$emit("onViewEquipment", equipment, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseEquipmentModal() {
      this.$emit("onCloseEquipmentModal");
    },
  },
};
</script>
